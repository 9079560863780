.light-blue{
    background: #85D4F0;
}

.active> .nav-item > .nav-link {
    color:white !important;
}

.idiomas-select{
    text-decoration: none;
    background: transparent;
    color: black !important;
    border: 0px;
}

.idiomas-select:active,.idiomas-select:focus{
    text-decoration: none;
    background: transparent;
    color: black !important;
    border: 0px;
}

select {
    appearance: none !important;
    border: 1px solid black !important;
    border-radius: 20% !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    margin-top: 5px;
    padding-left:8px !important;
    padding-right:8px !important;
}

.margin-auto{
    margin-left:8vw;
}

.idioma{
    margin-left:5vw;
}

.navbar-nav a{
    width:max-content;
}

@media screen and (max-width:999px){
.navbar-nav a {
    width: 100%;
}
}
@media screen and (max-width:500px){
    .logo{
        width:70 !important;
    }
    .logo-title{
        display: none !important;
    }

    .libera{
        display:none;
    }

}

@media screen and (min-width:501px){
.logo{
    position: absolute;
    left: 0;
    width: 130;
    height: 130;
}
.logo-title{
    width:220;
}

.logo-container{
    position: relative;
    width: 130;
    height: 50;
}
}


