.tu-1{
    background-image: url(./PORTADA.jpg); 
    position: fixed;
    width: 100%;
    top:50;
    height: max-content;
    display: block;
    background-position-x: center;
    background-size: cover;
}
