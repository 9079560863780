

.servi-container{
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    flex-direction: row;
    max-width: 1400px;
    margin-right: auto;
    margin-left: auto;
    vertical-align: middle;
    justify-content: space-around;
}

.klin-talks-img{
    position:absolute;
    left:10%;
    top:0;
    width:20%;
}

.prev, .next {
    padding-top: 200px;
    padding-bottom: 10px;
    margin: 0 auto;
    cursor: pointer;
    color: #22649A;
    transition: 0.6s ease;
    height:300px;
    font-size:28px;
    background: transparent;
    border: 0px;
  }

  .next {
    margin-left: 5px;
    margin-right: 5px;
  }
  .prev {
    margin-right: 5px;
    margin-left: 5px;
  }

  .prev:hover, .next:hover {
    color: white;
  }

.servi{
    display: -webkit-flex;
    display: -webkit-box;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    justify-content: unset !important;
    overflow-x: hidden;
    gap: 1vw;
    width: 100%;
    height:500px;
    overflow-x: scroll;
}
.scoll-pane {
    width: 100%;
    overflow: auto;
    outline: none;
    overflow-y: hidden;
    -ms-overflow-style: scroll;
    scrollbar-width: none; 
}

.titulo-prev h2{
    color:#22649A;
    font-size:1.5rem;
}

.scoll-pane::-webkit-scrollbar { 
    display: none;  
    }

.text-right{
    text-align:right;
}

.klin-talks{
    font-size:6vw;
}
.klintalks-container{
    background-color: #22649A;
}

.align-right{
    text-align:right;
}

.subtitle-klin-container{ 
    background-color: white;
}

.subtitle-klin{ 
    -webkit-text-fill-color: transparent;
    display:block;
    font-size: 80px;
    font-family: 'Cocogoose-Pro-trial';
    text-align: center;
    margin-top: -20px;
    font-weight: bold;
    background-size: cover;
}

.subtitle-klin span{
    background: url(http://www.color-hex.com/palettes/26323.png) -20px -20px repeat;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    display:block;
}

svg{width:100%;}

.talks {
    background-color: transparent;
    padding: 20px;
    margin-top: 20px;
    display:inline-flex;
}
.quote-container{
    width: 15vw;
    height: 15vw;
    /* background: rgb(125, 175, 128);
    border-radius: 50%;
    border: 10px solid white; */
    text-align: center;
    position: absolute;
    margin-left: 10vw;
    margin-top: 3vw;
    z-index: 1;
}

.plus-container{
    width: 6vw;
    height: 6vw;
    background: rgb(125, 175, 128);
    border-radius: 50%;
    border: 10px solid white;
    text-align: center;
    z-index: 1;
    margin-top: -2vw;
}

.talks-images{
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    border: 10px solid white;
    z-index: 1;
}

.inline{
    display:inline-flex;
    width:100%;
}

.servicios-container-div{
    background-color: #85D4F0;
}

.tarjeta{
    height: 250px;
  width: 250px;
  font-size: 25px;
  background: blue;
  margin: 10px;
  display: inline-block;
  line-height: 200px; 
  border-radius: 50%;
  border: 8px solid white;
}

.leer-container{
    position:absolute;
    font-size:4vw;
    color:#7DAF80;
    width: 20%;
    top: 5.8vw;
    display:inline-flex;
    right: 0;
}

.mas-simbolo{
   font-size:3vw;
   color:white;
   margin-top:1vw;
}

.leer-text{
    font-size:3vw;
    color:#7DAF80;
}

.klin-preview{
    font-size:20px;
    color:#22649A;
    font-family:Helvetica;
}

.card-text{
    color:White;
    font-size:18px;
}

.card-talk{
    color: White;
    font-size:18px;
}

.talk-title{
    font-size:30px;
    text-transform: uppercase;
    color:#80BAC6;
    font-size:1vw;
}

.titulo{
    margin-top:20px;
}

.blog-height{
    max-height:190px;
    overflow:hidden;
}

.mx-auto-klin{
    margin-left:auto;
    color:#22649A;
}

.liberatu{
    width: 70%;
    position: absolute;
    right: -90;
    margin-top: -100px;
}

@media screen and (max-width:901px){
    .liberatu {
        width: 100%;
        right: -80;
    }

    .klin-preview{
        font-size:17px;
    }
}

@media screen and (max-width:601px){

    .klin-talks-img{
        position:absolute;
        left:0%;
        top:0;
        width:30%;
    }

    .titulo-prev h2{
        color:#22649A;
        font-size:1rem;
    }

    .card-body-blog{
        font-size:18px;
    }

.card-talk{
    color: White;
    font-size: 16px;
}
    
    .servi{
        gap:0px;
    }

    .klin-preview{
        font-size:14px;
    }

    .blog-height{
        max-height: 182px;
        overflow:hidden;
    }

    .carousel-item.active, .carousel-item-next, .carousel-item-prev {
         margin-left: 0px !important; 
    }

    .talk-title{
        width:80%;
        font-size:20px;
    }

    .talks-images{
        position: absolute;
    width: 100px;
    height: 100px;
    margin-top: 10px;
    right: 8;
    }

    .card-text {
        color: White;
        font-size: 16px;
        width: 80%;
    }

    .blogs{
        margin-top:0px !important;
        padding-left:0px;
    }

    .sin-padding{
        padding-left:0px !important;
    }

    .leer-text{
        font-size:24px;
        color:#7DAF80;
    }

    .prev {
         margin-right: 0px;
        margin-left: 0px;
        font-size:20px;
    }

    .next {
        margin-right: 0px;
       margin-left: 0px;
       font-size:20px;
   }

   .talk-title{
    text-align:left !important;
   }

    .mas-simbolo{
        font-size:26px;
     }
    .col-8{
        width:100% !important;
    }

    .leer-container{
        width:150px;
        position:absolute;
        font-size:18px;
    }

    .tarjeta {
        height: 150px;
        width: 150px;
    }


    .prev, .next {
        padding-top: 90px;
    }

    .servi-container {
width:100%;
    }

    .klin-talks{
        font-size:32px;
    }

    .plus-container {
        width: 60px;
        height: 60px;

    }

    .servi {
        height:320px;
    }
    .titulo-prev h3 {
font-size:18px;
left:0;
    }

    .quote-container{
        margin-top:0px !important;
        width: 100px;
        height:100px;
        margin-left:0px !important;
    }

    @media screen and (max-width:501px){
        .klin-talks-img{
            position:absolute;
            left:-5%;
            top:0;
            width:35%;
        }

        .klin-preview{
            font-size:12px;
            margin-left: 5%;
    margin-right: 5%;
        }
    }
}