body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family:Cocogoose-Pro-Italic-trial;
  src: url(./Fonts/Cocogoose-Pro-Italic-trial.ttf);
}
@font-face {
  font-family: Cocogoose-Pro-Light-Italic-trial;
  src: url(./Fonts/Cocogoose-Pro-Light-Italic-trial.ttf);
}
@font-face {
  font-family: Cocogoose-Pro-Light-trial;
  src: url(./Fonts/Cocogoose-Pro-Light-trial.ttf);
}
@font-face {
  font-family: Cocogoose-Pro-Semilight-Italic-trial;
  src: url(./Fonts/Cocogoose-Pro-Semilight-Italic-trial.ttf);
}
@font-face {
  font-family: Cocogoose-Pro-Semilight-trial;
  src: url(./Fonts/Cocogoose-Pro-Semilight-trial.ttf);
}
@font-face {
  font-family: Cocogoose-Pro-Thin-Italic-trial;
  src: url(./Fonts/Cocogoose-Pro-Thin-Italic-trial.ttf);
}
@font-face {
  font-family: Cocogoose-Pro-Thin-trial;
  src: url(./Fonts/Cocogoose-Pro-Thin-trial.ttf);
}
@font-face {
  font-family: Cocogoose-Pro-trial;
  src: url(./Fonts/Cocogoose-Pro-trial.ttf);
}


code {
  font-family: Cocogoose-Pro-trial,source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
