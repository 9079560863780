.tkl-1{
    background-image: url(./PORTADA.jpg); 
    position: fixed;
    width: 100%;
    top:50;
    height: max-content;
    display: block;
    background-position-x: center;
    background-size: cover;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background:linear-gradient(#22649A,#22649A) center/2px 100% no-repeat;
    grid-gap: 16px;
    margin:5px;
  }
  
  .grid-item {
    display:flex;
    flex-flow:column;
    position: relative;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
  
  
  .grid-container.another {
    grid-template-columns: 2fr 1fr;
    background-position:66.5% 0;
  }
  
  .grid-container.column-3 {
    grid-template-columns: 1fr 1fr 1fr;
    background:
      linear-gradient(#000,#000) center/2px 100% no-repeat,
      linear-gradient(#000,#000) center/2px 100% no-repeat;
    background-position:33% 0, 67% 0;
  }

  @media screen and (max-width:600px){
    .grid-item {

        width: 100%;
        margin-right: auto;
        margin-left: auto;
      }
      .grid-container {
        margin:0px;
        padding:0px;
      }

  }