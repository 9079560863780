
  
  /* Add a gray background color with some padding */
  body {
    font-family: 'Cocogoose-Pro-trial';
    background: white;
  }

  .image-gallery-svg{
    display:none;
  }

  .lib-det {
    margin-top: -200px !important;
}

  .image-gallery-image{height: 500px !important;
    width: 500px !important;
    object-fit: cover !important}

  .image-gallery-thumbnail-image{height: 80px;
    object-fit: cover;}
  
  .row{
    padding:20px;
    height: max-content !important;
  }

  .fa-2x{
    padding-left:7px;
    padding-right:7px;
  }

  .info{
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
  /* Header/Blog Title */
  .header {
    padding: 30px;
    font-size: 40px;
    text-align: center;
    background: white;
  }
  
  /* Create two unequal columns that floats next to each other */
  /* Left column */
  .leftcolumn {   
    float: left;
    width: 75%;
  }
  
  /* Right column */
  .rightcolumn {
    float: left;
    width: 25%;
    padding-left: 20px;
  }
  
  /* Fake image */
  .fakeimg {
    background-color: transparent;
    width: 100%;
    padding: 20px;
    text-align: center;
  }
  
  /* Add a card effect for articles */
  .card {
     background-color: white;
     padding: 20px;
     margin-top: 20px;
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Responsive layout - when the screen is less than 800px wide, make the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 800px) {
    .leftcolumn, .rightcolumn {   
      width: 100%;
      padding: 0;
    }
  }