@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,200,200italic,300,300italic,400italic,600,600italic,700,700italic,900,900italic);


.popup{
    background-color: white;
    width: 60%;
    min-width:400px;
    height:800px;
    padding: 30px 40px;
    position: absolute;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
    border-radius: 8px;
    font-family: "Poppins",sans-serif; 
    text-align: center;
    z-index:10000;
    
}

.popup-container {
    position: fixed;
    width: 100%;
    height: 100%;
    padding-top: 200;
    top: 0;
    z-index: 1000000;
    background: #8080809e;
}

.popup button{
    display: block;
    margin:  0 0 20px auto;
    background-color: transparent;
    font-size: 20px;
    color: #5a5a5a;
    background: #ffffff;
    border-radius: 100%;
    width: 28px;
    height: 28px;
    border: none;
    outline: none;
    cursor: pointer;
    border:1px solid #5a5a5a;
    line-height: 0px !important;
}
.ty-1{
    background-image: url(./PORTADA.jpg); 
    position: fixed;
    width: 100%;
    top:50;
    height: max-content;
    display: block;
    background-position-x: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.smile{
    position: absolute;
    z-index: 1;
    width: 30vw;
    height: 30vw;
    left: 3vw;
    margin-top: -150px;

}

.blanco{
    color:white !important;
}

.blue{
    color:#22649A
}
.content-2 {
    margin-top: 350px;
}

.contenido-titulo{
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
}

body {
    font-family: 'Cocogoose-Pro-Light-trial','Source Sans Pro', sans-serif;
    line-height: 1.5;
    color: #323232;
    font-size: 15px;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
}
.heading-title {
    margin-bottom: 100px;
}
.text-center {
    text-align: justify !important;
}
.heading-title h3 {
    margin-bottom: 0;
    letter-spacing: 2px;
    font-weight: normal;
}
.p-top-30 {
    padding-top: 30px;
}
.half-txt {
    width: 60%;
    margin: 0 auto;
    display: inline-block;
    line-height: 25px;
    color: #7e7e7e;
}
.text-uppercase {
    text-transform: uppercase;
}

.team-img >img {
    height: 100%;
    float: right;
}

@media (max-width: 768px){
.col-md-memb {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    height: 450px;
}
.smile{
    display:none;
}
.centro{
    text-align: center !important;
}

.ty-1{
    background-size: cover !important;
}
}


@media screen and (max-width: 576px){
.col-sm-memb {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    height:max-content !important;
}
.smile{
    display:none;
}

.docdesc{
    display:none;
}
.team-member {
    overflow: hidden;
    box-shadow: 0px 0px 5px -3px rgb(0 0 0 / 50%);
    width:150px !important;
    height:150px !important;
    border-radius:50%
}

.members {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
}

.team-title {
    position: static;
    padding: 20px 0;
    display: inline-block;
    letter-spacing: 2px;
    width: 180px !important;
}

.team-member {
    overflow: hidden;
    box-shadow: 0px 0px 5px -3px rgb(0 0 0 / 50%);
    width:150px;
    height:150px;
    border-radius:50%;
    margin-left:auto;
    margin-right:auto;
}
}

.members {
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
    gap: 1.4vw;
    text-align: center;
}

.team-member, .team-member .team-img {
    position: relative;
}
.team-member {
    overflow: hidden;
    box-shadow: 0px 0px 5px -3px rgb(0 0 0 / 50%);
    width:250px;
    height:250px;
    border-radius:50%
}
.team-member, .team-member .team-img {
    position: relative;
}

button[type="submit"] {
    margin-top: 16px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #333;
    color: white;
    font-size: 16px;
  }

.team-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    background-color: #85d3f08c;
    opacity: 0;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
.team-member:hover .team-hover .desk {
    top: 35%;
}
.team-member:hover .team-hover, .team-member:hover .team-hover .desk, .team-member:hover .team-hover .s-link {
    opacity: 1;
}
.team-hover .desk {
    position: absolute;
    top: 0%;
    width: 100%;
    opacity: 0;
    -webkit-transform: translateY(-55%);
    -ms-transform: translateY(-55%);
    transform: translateY(-55%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    padding: 0 20px;
}
.desk, .desk h4, .team-hover .s-link a {
    text-align: center;
    color: #222;
}
.team-member:hover .team-hover .s-link {
    bottom: 10%;
}
.team-member:hover .team-hover, .team-member:hover .team-hover .desk, .team-member:hover .team-hover .s-link {
    opacity: 1;
}
.team-hover .s-link {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
    text-align: center;
    -webkit-transform: translateY(45%);
    -ms-transform: translateY(45%);
    transform: translateY(45%);
    -webkit-transition: all 0.3s 0.2s;
    transition: all 0.3s 0.2s;
    font-size: 35px;
}
.desk, .desk h4, .team-hover .s-link a {
    text-align: center;
    color: #222;
}
.team-member .s-link a {
    margin: 0 10px;
    color: #333;
    font-size: 16px;
}
.team-title {
    position: static;
    padding: 20px 0;
    display: inline-block;
    letter-spacing: 2px;
    width: 250px;
}
.team-title h5 {
    margin-bottom: 0px;
    display: block;
    text-transform: uppercase;
}
.team-title span {
    font-size: 12px;
    text-transform: uppercase;
    color: #a5a5a5;
    letter-spacing: 1px;
}
