.tt1 {
  background-image: url(./portada-tec.jpg);
  position: fixed;
  width: 100%;
  top: 0;
  background-size: cover;
  height: max-content;
  display: block;
  background-position-x: center;
}

@media (min-width: 768px){
.col-md-120 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
    height: max-content;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    max-width:800px;
    margin-right: auto;
    margin-left:auto;
    margin-bottom:50px;

}
}

.col-md-120 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 100%;
    height: max-content;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    max-width:800px;
    margin-right: auto;
    margin-left:auto;
    margin-bottom:50px;

}
a:hover{
text-decoration: none !important;
}

.tecnologia-text{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom:20px;
    background: #80C5E2;
}

.tecnologia-text h4{
  padding-left: 10%;
    padding-right: 10%;
}

.contenido-titulo h1{
  font-weight:400 !important;
    text-transform: uppercase !important;
}

.tecnologia-desc h4{
   font-size:16px;
}

.tecnologia-desc-title{
  padding:0;
}

.tecnologia-desc-text{
  font-size:20px;
}

.horizontal-line{
    background-color: #80BAC6;
    height:8px;
    width:80%;
    position: absolute;
    top:30px;
}

.imagen-fondo{
    position:absolute;
    right:0;
}

.vertical-line{
    background-color: #80BAC6;
    height:8px;
    width: 30%;
    position: absolute;
    transform: rotate(90deg);
    right: -65;
    top: 200px;
}

.hover:hover{
    color:#1c529e
  }
  
  .border-right{
    border-right: 1px solid lightgrey;
  }

  .space{
    padding-right:10px;
    padding-left:10px;
  }

  .tech-text-container{
    padding:3% 0;
    height: 250px;
  }

  .menu-prod{
    padding-top:20px;
    padding-bottom:20px;
    background-color:white;
    border-bottom: 1px solid rgba(43, 43, 43, 0.164);
    width: 100%;
    overflow: hidden;
    text-align:center;
  }
  
  .product-menu{
   font-size: 18px;
  }
  
  @media screen and (max-width:1000px){
    .product-menu{
    font-size: 14px;
    }
  }

  @media screen and (max-width:901px){
  .tecnologia-text .team-member{
    display:none;
  }
}

  @media screen and (max-width:700px){
    .menu-prod{
    padding-top: 20px;
      padding-bottom: 20px;
      background-color: white;
      border-bottom: 1px solid black;
      width: 100%;
      overflow-x: scroll !important;
    }
    
    .menu-prod::-webkit-scrollbar {
    display: none;
  }
    
    .product-menu{
   font-size: 14px;
   width: 780px;
  }

  .tecnologia-desc h4{
    font-size:12px;
 }

 .tecnologia-desc-title{
  font-size:16px;
}

.tech-text-container{
  padding:3% 0;
  height: 180px;
}

.tecnologia-text h4 {
  padding-left: 5%;
  padding-right: 5%;
}
  }
  
  html { scroll-behavior: smooth; }