

.ay-1{
  background-image: url(./AGENDA.jpg); 
  position: fixed;
  width: 100%;
  top:0;
  background-size: cover;
  height: max-content;
  display: block;
  background-position-x: center;
}

.agenda {
  max-width: 1000px;
  display: flex; 
  flex-wrap: wrap; 
  justify-content: center !important;
  gap: 10px;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif !important;
}

.info-detalle{
  max-width: 500px;
    font-family: 'Source Sans Pro', sans-serif !important;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 1rem;
}

.pay-8{
  padding-bottom: 7.5rem !important;
}

#horario{
  border-radius: 4% !important;
}
form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
  }
  label {
    margin: 8px 0;
  }
  input, textarea {
    padding: 8px;
    border: 1px solid #333;
    border-radius: 4px;
    font-size: 16px;
  }
  button[type="submit"] {
    margin-top: 16px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #333;
    color: white;
    font-size: 16px;
  }

  .info-agenda{
    display:grid;
    grid-template-columns: repeat(2,1fr);
  }

  .entrada{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    padding: 0 10px 0 10px;
  }

  .info-notas{
    display:grid;
    grid-template-columns: repeat(1,1fr);
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 30px;
  }

  .form-boton{
    margin-right: auto;
    margin-left: auto;
    display: block;
  }


  @media screen and (max-width:601px){
    .agenda {
      max-width: 1000px;
       display: block;
      flex-wrap:unset;
      justify-content: center !important;
      gap: 10px;
      width: 100%;
  }

  .appointment-content {
    overflow-x: scroll !important;
}
  }