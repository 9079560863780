.dot-container{
    position: fixed;
    bottom: 0;
    z-index: 100;
    display: grid;
}



.dot-container-right{
    position: fixed;
    bottom: 0;
    z-index: 100;
    display: grid;
    right:0;
}

.dot {
  transform: translateY(-5px);
    margin:15px;
    height: 80px;
    width: 160px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding: 10% 0;
  }


  .dot-right {
    box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.5);
  transform: translateY(-5px);
    margin:15px;
    height: 70px;
    width: 70px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding: 10% 0;
  }

