
.Contact{
    display:flex;
    background: white;
    justify-content: center;
}

.info{
    text-align:center;
    margin-top:40px
}

.Contact p{
    font-family: 'Source Sans Pro', sans-serif;;
}

.contact-datos{
padding-left:4vw;
font-size:12px;
color:#22649A;
margin-bottom:.5rem;
}

.contact-info{
padding-left: 4vw;
padding-right: 4vw;
font-size:12px;
color:#22649A;
}

.contact-big{
    padding-left:4vw;
font-family: 'Cocogoose-Pro-trial';
color:#22649A;
}

@media screen and (max-width:950px){
    .Contact{display:inherit;position:relative;margin-right: auto;margin-left: auto;}
    .Contact > .rightcolumn,.leftcolumn{
        width:100%;
        text-align: center;
    }

    .Contact  p{
        padding-left:0px;
        padding-right:0px;
    }
}
@media screen and (max-width:890px){
    .Contact{display:inherit;position:relative;margin-right: auto;margin-left: auto;}

    .container-form{
        width:100%
    }

    input, textarea {
        width: 100%;
    }
}