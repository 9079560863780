/* .servicios{
    margin-bottom:50px;
} */

.cards a{
    text-decoration:none;
}

.titulo-prev{
    color:White;
    position:relative;
    height: 70px;
}

.libera{
    position: absolute;
    z-index: 1;
    width: 50vw;
    height: 10vw;
    right: -23%;
    transform: rotate(-90deg);
    margin-top: 300px;
}

.titulo-prev h3 {
    position: relative;
    bottom: 0;
    color: rgb(32, 105, 173);
    left: 10;
    font-weight:800;
}
.servicios-cont{
    width:30%;
    text-align: center;
    height: max-content;
    margin-bottom: 20px;
}
.cards{
        padding: 20px;
        background-color: #89d1ddd4;
        display: none;
        transition: all 1s linear;
        -moz-transition: all 1s linear; /* Firefox 4 */
        -webkit-transition: all 1s linear; /* Safari and Chrome */
        -o-transition: all 1s linear; /* Opera */
        -ms-transition: all 1s linear; /* Explorer 10 */
}

/* .tarjeta:hover .cards{
    display: block;
} */

.py-1{
    background-image: url(https://www.cochrane.org/sites/default/files/public/styles/social_media/public/uploads/news/young-confident-asian-male-dentist-medical-treatment-to-a-female-patient-at-the-clinic.-dental-clinic-concept-911844046_1257x838_3.jpeg?itok=Gf5izRh3);
}

.sy-1{
    background-image: url(./SERVICIOS.jpg); 
    position: fixed;
    width: 100%;
    top:0;
    background-size: cover;
    height: max-content;
    display: block;
    background-position-x: center;
    background-position: right;
}


.contenido {
    padding-top: 500px;
}

.content-1{
    margin-top:300px;
}

.white{
    background-color:white;
}

.services{
    max-width: 1000px;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    gap:10px;}

    @media screen and (max-width:851px){
        .servicios-cont{
            width:40%;
            text-align: center;
            height: max-content;
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width:651px){
        .servicios-cont{
            width:40%;
            text-align: center;
            height: max-content;
            margin-bottom: 20px;
        }

        .tarjeta {
            height: 200px;
            width: 200px;
        }

        .sy-1 {
            position: fixed;
            width: 195%;
            top: 0;
            background-size: contain;
            height: max-content;
            display: block;
            left: -90vw;
            background-position-x: center;
            background-position: center;
            background-repeat: no-repeat;
        }

        .titulo-serv{
            padding-left: 90vw !important;
        }
    }

    @media screen and (max-width:501px){
        .tarjeta {
            height: 150px;
            width: 150px;
        }
    }